<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="gambo-Breadcrumb">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home </router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link
                      :to="'/categories/' + category.id"
                      class="see-more-btn">
                      {{ category.name }}
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active">
                    <router-link
                      :to="'/categories/' + category.id + '/stores/' + store.id"
                      class="see-more-btn">
                      {{ store.name }}
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ product.name }}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <!-- Loader Section -->
      <div v-if="isLoading" class="loader-class">
        <img
          style="width: 100px"
          src="../../assets/loader.gif"
          alt="Loading..." />
      </div>

      <!-- Not Found Section -->
      <div v-else-if="isNotFound" class="not-found-class">
        <img
          style="width: 300px"
          src="../../assets/not-found.png"
          alt="Not Found" />
        <p class="not-found-text">Product not found</p>
      </div>

      <div v-else class="all-product-grid">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="product-dt-view">
                <div class="row">
                  <div class="col-lg-4 col-md-4">
                    <carousel :per-page="1" :mouse-drag="true" :autoplay="true">
                      <slide v-if="product.imagepath1">
                        <img
                          style="height: 300px"
                          class="item"
                          :src="
                            product.imagepath1
                              ? `${medialURL}${product.imagepath1}`
                              : '/images/product/img-1.jpg'
                          "
                          alt="" />
                      </slide>
                      <slide v-if="product.imagepath2">
                        <img
                          style="height: 300px"
                          class="item"
                          :src="
                            product.imagepath2
                              ? `${medialURL}${product.imagepath2}`
                              : '/images/product/img-1.jpg'
                          "
                          alt="" />
                      </slide>
                      <slide v-if="product.imagepath3">
                        <img
                          style="height: 300px"
                          class="item"
                          :src="
                            product.imagepath3
                              ? `${medialURL}${product.imagepath3}`
                              : '/images/product/img-1.jpg'
                          "
                          alt="" />
                      </slide>
                      <slide v-if="product.imagepath4">
                        <img
                          style="height: 300px"
                          class="item"
                          :src="
                            product.imagepath4
                              ? `${medialURL}${product.imagepath4}`
                              : '/images/product/img-1.jpg'
                          "
                          alt="" />
                      </slide>
                    </carousel>
                  </div>
                  <div class="col-lg-8 col-md-8">
                    <div class="product-dt-right">
                      <h2 class="d-flex" style="align-items: center">
                        {{ product.name }}
                        <span class="mx-1" v-if="product.catelog_no != ''"
                          >|</span
                        >
                        {{ product.catelog_no || "" }}
                      </h2>
                      <div class="no-stock">
                        <p class="pd-no">
                          Product No.<span>{{ product.id }}</span>
                        </p>
                      </div>
                      <div class="product-radio">
                        <ul class="product-now">
                          <li
                            v-for="(price, p) in product.product_prices"
                            :key="`price${p}`">
                            <input
                              type="radio"
                              :id="`p${p}`"
                              name="product1"
                              @click="
                                updateProductPriceByDropDownFrontEnd(
                                  product,
                                  price.id
                                )
                              " />
                            <label :for="`p${p}`"
                              >{{ price.product_pack.value }}
                              {{ price.product_pack.unit.name }}</label
                            >
                          </li>
                        </ul>
                      </div>
                      <p>{{ product.small_description }}</p>
                      {{ product.product_price.pack_value }}
                      {{ product.product_price.unit }}
                      <div class="product-group-dt">
                        <ul
                          v-if="
                            product.product_price.offer_percent != 0 ||
                            product.product_price.cashback_price
                          ">
                          <li v-if="product.product_price.offer_price">
                            <div
                              class="main-price color-discount"
                              v-if="product.product_price.offer_price">
                              Discount Price<span
                                >₹ {{ product.product_price.offer_price }}</span
                              >
                            </div>
                          </li>
                          <li>
                            <div class="main-price mrp-price">
                              MRP Price<span
                                >₹ {{ product.product_price.price }}</span
                              >
                            </div>
                          </li>
                          <li>
                            <div
                              class="main-price"
                              v-if="product.product_price.cashback_price">
                              Cashback<span
                                >₹
                                {{ product.product_price.cashback_price }}</span
                              >
                            </div>
                          </li>
                        </ul>
                        <ul v-else>
                          <li>
                            <div class="main-price">
                              MRP Price<span
                                >₹ {{ product.product_price.price }}</span
                              >
                            </div>
                          </li>
                        </ul>
                        <ul
                          class="ordr-crt-share"
                          v-if="
                            order.order_details.find(
                              (d) => d.product_id == product.id
                            ) == null
                          ">
                          <li>
                            <button
                              class="add-cart-btn hover-btn"
                              @click="increementProduct(0, product)">
                              <i class="uil uil-shopping-cart-alt"></i>Add to
                              Cart
                            </button>
                          </li>
                        </ul>
                        <ul class="gty-wish-share" v-else>
                          <li>
                            <div class="qty-product">
                              <div class="quantity buttons_added">
                                <input
                                  type="button"
                                  value="-"
                                  class="minus minus-btn"
                                  @click="
                                    decreementProduct(
                                      order.order_details.find(
                                        (d) => d.product_id == product.id
                                      ),
                                      product
                                    )
                                  " />
                                <input
                                  type="number"
                                  step="1"
                                  :value="
                                    order.order_details.find(
                                      (d) => d.product_id == product.id
                                    ).quantity
                                  "
                                  class="input-text qty text" />
                                <input
                                  type="button"
                                  value="+"
                                  class="plus plus-btn"
                                  @click="
                                    increementProduct(
                                      order.order_details.find(
                                        (d) => d.product_id == product.id
                                      ).quantity,
                                      product
                                    )
                                  " />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="pdp-details">
                        <ul>
                          <li>
                            <div class="pdp-group-dt">
                              <div class="pdp-icon">
                                <i class="uil uil-circle"></i>
                              </div>
                              <div>
                                <span>Lowest Price Guaranteed</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="pdpt-bg">
                <div class="pdpt-title">
                  <h4>Product Details</h4>
                </div>
                <div class="pdpt-body scrollstyle_4">
                  <div class="pdct-dts-1">
                    <br />
                    {{ product.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex";
import axios from "axios";
import {
  selectDefaultProductPrice,
  updateProductPriceByDropDown,
  addOrderDetail,
} from "@/helpers/index.js";
import { Carousel, Slide } from "vue-carousel";

export default {
  data() {
    return {
      category: {},
      store: {},
      product: {
        product_price: {},
      },
      isLoading: true, // Added loading state
    };
  },
  computed: {
    ...mapGetters({
      categories: "category/getCategories",
      medialURL: "settings/getMediaUrl",
      order: "order/getOrder",
    }),
  },
  watch: {
    "$route.params.productId"(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getData();
      }
    },
  },
  components: {
    Carousel,
    Slide,
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true; // Set loading to true before data fetch
      this.category = this.categories.find(
        (category) => category.id == this.$route.params.categoryId
      );
      this.store = this.category.companies.find(
        (company) => company.id == this.$route.params.storeId
      );
      let product = await axios.get(
        `products/${this.$route.params.productId}`,
        {
          headers: {
            "company-id": this.store.id,
          },
        }
      );
      product = product.data.data;
      selectDefaultProductPrice(product, this.order);
      this.product = product;
      this.isLoading = false; // Set loading to false after data is fetched
    },
    updateProductPriceByDropDownFrontEnd(product, priceId) {
      console.log(priceId);
      updateProductPriceByDropDown(product, priceId, this.order);
    },
    decreementProduct(orderDetail, product) {
      let orderDetailQuantity = orderDetail.quantity;
      if (orderDetailQuantity > 1) {
        product.product_price.selectedQuantity = orderDetailQuantity - 1;
        this.addOrderDetailFrontEnd(product);
      } else if (orderDetailQuantity > 0) {
        this.$store.dispatch("order/removeOrderDetail", orderDetail);
      }
    },
    increementProduct(orderDetailQuantity, product) {
      if (
        this.order.store.id == null ||
        this.order.store.id == "" ||
        this.order.store.id == this.store.id
      ) {
        product.product_price.selectedQuantity = orderDetailQuantity + 1;
        this.addOrderDetailFrontEnd(product);
      } else {
        alert(
          "Order from another store has already been added. Please remove the order and try again"
        );
      }
    },
    addOrderDetailFrontEnd(product) {
      let orderDetail = addOrderDetail(this.store, product);
      this.$store.dispatch("order/addOrderDetail", orderDetail);
    },
  },
};
</script>

<style scoped>
.item {
  width: 100% !important;
}
</style>
